import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { 
    API_RESOURCE_LOCATION, 
    API_RESOURCE_PRODUCT,
    API_RESOURCE_OFFER, 
} from 'Consts/apiResources';
import { PUBLIC_RESERVATIONS_ONLINE } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { getActivePromoCodeInLocation } from 'Utils/promoCode';
import { withVariables } from 'Utils/string';

import Head from 'Components/core/Head';
import StyledComponent from 'Components/core/StyledComponent';
import LayoutContainer from 'Components/layout/LayoutContainer';
import Spinner from 'Components/layout/Spinner';
import TrainingSelectForm from 'Components/public/reservations/SlotSelectForm';
import CheckoutForm from 'Components/public/reservations/CheckoutForm';

export default class PublicReservations extends Component {
    productId = process.env.APP_ENV == 'development' || process.env.APP_ENV == 'staging'
        ? '6609fa6b-7ab3-499c-a9ba-2abf8f6b8e06'
        : '37b14a7e-a343-406a-98a4-614b55a5470a';

    offerSlug = 'diagnostyczna-rezerwacje';

    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                slug: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        actions: PropTypes.shape({
            getLocationBySlug: PropTypes.func.isRequired,
            singleOfferBySlug: PropTypes.func.isRequired,
            singleProduct: PropTypes.func.isRequired,
            singleShopBySlug: PropTypes.func.isRequired,
        }).isRequired,
        shopPage: PropTypes.shape({
            isLoading: PropTypes.bool.isRequired,
            isLoaded: PropTypes.bool.isRequired,
            data: PropTypes.object,
        }),
    };

    static defaultProps = {};

    state = {
        selectedLocation: null,
        selectedOffer: null,
        selectedProduct: null,
        selectedSlot: null,
        formState: null,
    }

    componentDidMount = () => {
        const { history, match } = this.props;

        this.loadLocation();
        this.loadProduct();
        this.loadOffer();
        this.loadShop();

        if(match?.params?.slug === 'online') {
            history.push(
                withVariables(
                    PUBLIC_RESERVATIONS_ONLINE.path,
                )
            );
        }
    }

    loadProduct = () => {
        const { actions } = this.props;

        actions.singleProduct({ id: this.productId })
            .then(response => {
                this.setState({ selectedProduct: response.payload[API_RESOURCE_PRODUCT] });
            });
    }

    loadOffer = () => {
        const { actions } = this.props;

        actions.singleOfferBySlug({ slug: this.offerSlug })
            .then(response => {
                this.setState({ selectedOffer: response.payload[API_RESOURCE_OFFER] });
            });
    }

    loadShop = () => {
        const { actions, match } = this.props;

        actions.singleShopBySlug({
            slug: match.params.slug
        });
    }

    loadLocation = () => {
        const { actions, match } = this.props;

        actions.getLocationBySlug({ slug: match.params.slug })
            .then(response => {
                const location = response.payload[API_RESOURCE_LOCATION];
                const discountCode = getActivePromoCodeInLocation(location);

                this.setState(prevState => ({
                    ...prevState,
                    selectedLocation: location,
                    formState: {
                        ...prevState.formState,
                        comment: discountCode,
                    },
                }));
            });
    }

    onTrainingSelectFormSubmit = data => {
        this.setState({ selectedSlot: data });
    }

    onResetTraining = () => {
        this.setState({
            selectedSlot: null,
        });
    }

    onFormStateChange = (formState) => {
        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...formState,
            },
        }));
    }

    render() {
        const { selectedProduct, selectedLocation, selectedOffer, selectedSlot, formState } = this.state;
        const { location, history, shopPage } = this.props;
        const queryObject = parseQueryToObject(location?.search || '', true);

        if (!selectedOffer || !selectedLocation || !selectedProduct || !shopPage.isLoaded) {
            return (
                <Spinner />
            );
        }

        return (
            <StyledComponent
                className={classnames({
                    'component-reservations': true,
                })}
                styles={require('./styles')}
            >
                <Head
                    title="Rezerwacje treningów"
                    description="Rezerwacje treningów FitAdept"
                />
                <LayoutContainer>
                    {!selectedSlot && (
                        <div className="content content-training-select">
                            <div className="body">
                                <div className="training-select-container">
                                    <TrainingSelectForm
                                        location={location}
                                        history={history}
                                        selectedLocation={selectedLocation}
                                        selectedProduct={selectedProduct}
                                        leadId={queryObject?.leadId}
                                        onSubmit={this.onTrainingSelectFormSubmit}
                                        lagValue={shopPage?.data?.lagValue}
                                        lagType={shopPage?.data?.lagType}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {selectedSlot && (
                        <div className="content content-checkout">
                            <div className="body">
                                <div className="checkout-container">
                                    <CheckoutForm
                                        location={location}
                                        history={history}
                                        onResetTraining={this.onResetTraining}
                                        onFormStateChange={this.onFormStateChange}
                                        data={formState}
                                        showComment={true}
                                        selectedOffer={selectedOffer}
                                        selectedLocation={selectedLocation}
                                        selectedProduct={selectedProduct}
                                        selectedSlot={selectedSlot.slot}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </LayoutContainer>
            </StyledComponent>
        );
    }
}
